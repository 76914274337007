html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input,
input:focus {
  -webkit-appearance: none;
  outline: none;
  border-radius: 0;
}

@import url('https://fonts.googleapis.com/css?family=Rubik:400,500');

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 10px;
  color: #111;
}

Button {
  font-family: 'Rubik', sans-serif;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  body {
    font-size: 13px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  body {
    font-size: 15px;
  }
}
