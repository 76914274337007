@import url(https://fonts.googleapis.com/css?family=Rubik:400,500);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input,
input:focus {
  -webkit-appearance: none;
  outline: none;
  border-radius: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 10px;
  color: #111;
}

Button {
  font-family: 'Rubik', sans-serif;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  body {
    font-size: 13px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  body {
    font-size: 15px;
  }
}

.Modal {
  font-size: 10px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .Modal {
    font-size: 13px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .Modal {
    font-size: 16px;
  }
}

.Modal-window {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 75%;
  max-width: 900px;
  height: 85%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  z-index: 10;
  box-shadow: 0 8px 6px -6px #000;
}

.Modal-window h1 {
  display: block;
  text-align: center;
  font-size: 150%;
  margin-top: 7px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Modal-window h2 {
  display: block;
  text-align: center;
  font-size: 120%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Modal-area {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.Modal-area p {
  margin-bottom: 10px;
  line-height: 150%;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .Modal-area p {
    margin-bottom: 15px;
  }
}

.Modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .7);
}

.Modal-exit {
  position: absolute;
  right: 0;
  margin-right: 20px;
  font-weight: 500;
  border: 2px solid rgba(0, 0, 0, .3);
  color: rgba(0, 0, 0, .3);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: #fff;
}

.Modal-exit::after {
  content: 'x';
}

.Modal-exit:hover {
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, .9);
  color: rgba(0, 0, 0, .9);
}

.ActionButton {
  text-align: center;
  background-color: #3c82d1;
  color: #fff;
  border: 0;
  border-radius: 5px;
  margin: 4px;
  height: 40px;
  padding: 0 20px;
  font-size: 90%;
  max-width: 100%;
  cursor: pointer;
  text-transform: uppercase;
}

.ActionButton:focus {
  outline: none;
}

.ActionButton:hover {
  opacity: .8;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .ActionButton {
    height: 45px;
    margin: 7px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .ActionButton {
    height: 55px;
  }
}

.ActionButton-red {
  background-color: #dc3a1f;
}

.ActionButton-green {
  background-color: #35a987;
}

.ActionButton-blue {
  background-color: #3c82d1;
}

.ActionButton-disabled {
  pointer-events: none;
  opacity: .3;
}

.ActionButton-disabled:hover {
  opacity: .3;
  cursor: default;
}

.NumPad hr {
  border: 0;
  height: 0;
}

.NumPad .Button {
  text-align: center;
  background-color: #3c82d1;
  color: #fff;
  border: 0;
  border-radius: 5px;
  margin: 4px;
  width: 40px;
  height: 40px;
  font-size: 120%;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-appearance: none;
  padding: 0;
}

.NumPad .Button:focus {
  outline: none;
}

.NumPad .Button:hover {
  opacity: .8!important;
}

.NumPad .Button-selected {
  border: 2px solid rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.3);
}

.ThrowCounter {
  margin: 20px 0 15px 0;
}

.NumPad .ThrowCounter .throws {
  display: inline-block;
  margin: 0;
  font-size: 120%;
  width: 115px;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.NumPad .ThrowCounter .throws b {
  font-weight: 500;
}

.NumPad .ThrowCounter .ActionButton {
  height: 35px;
  width: 35px;
  padding: 0;
  margin: 0;
}


@media screen and (min-width: 400px) and (min-height: 510px) {
  .NumPad .Button {
    width: 45px;
    height: 45px;
    margin: 7px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .NumPad .Button {
    width: 55px;
    height: 55px;
  }
}

.ScoreBox {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .ScoreBox {
    font-size: 85%;
  }
}

.pointer-device .ScoreBox:hover {
  background-color: rgba(0, 0, 0, .05);
}

.ScoreBoxValue {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ScoreBoxValue-wiped::after {
  content: 'x';
  color: #dc3a1f;
}

.Player-done .ScoreBoxValue {
  position: relative;
  opacity: .3;
  z-index: 0;
}

.SumBox {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .07);
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .SumBox {
    font-size: 85%;
  }
}

.SumBox-balance {
  font-size: 90%;
}

.SumBox .SumBox-balance--positive {
  color: #3c82d1;
}

.SumBox .SumBox-balance--negative {
  color: #dc3a1f;
}

.SumBox-warning {
  color: #dc3a1f;
}

.Player {
  width: calc(90vh / 20);
  min-width: 25px;
  height: 100%;
  float: left;
  border-right: 1px solid rgba(0, 0, 0, .4);
}

.Player-name {
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.Player-throws {
  background-color: #35a987;
  color: #fff;
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
  pointer-events: none;
}

.Player-throws.positive-false {
  background-color: #dc3a1f;
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .Player-name {
    height: 85px;
  }

  .Player-throws {
    height: 20px;
    width: 20px;
    margin-left: -4px;
    margin-top: -4px;
    font-size: 10px;
    line-height: 20px;
  }
}

.Player-name:hover {
  cursor: pointer;
}

.pointer-device .Player-name:hover {
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  padding-top: 1px;
}

.Player-name p {
  position: relative;
  z-index: 0;
  writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
          text-orientation: mixed;
  transform: rotate(180deg);
}

.Player-leader .Player-name p {
  font-weight: 500;
}

.Player-active {
  background-color: rgba(53, 169, 135, 0.1);
}

.Player-inactive .ScoreBox-disabled {
  pointer-events: none;
}

.Player-winner .GrandTotal {
  color: #3c82d1;
  font-weight: 500;
}

.Scoresheet {
  height: 100%;
  float: left;
}

.Scoresheet-column {
  height: 100%;
  float: left;
  border-right: 1px solid rgba(0, 0, 0, .5);
}

.Scoresheet-main {
  height: calc(100% - 70px);
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .Scoresheet-main {
    height: calc(100% - 86px);
  }
}

.InfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 0 10px;
}

.InfoBox-header {
  height: 70px;
  display: block;;
}

.InfoBox-header p {
  font-size: 50%;
  text-align: right;
  margin-top: -5px;
}

.InfoBox-header img {
  width: 70px;
  margin-top: 20px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .InfoBox-header img {
    width: 90px;
    margin-top: 13px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .InfoBox-header {
    height: 86px;
  }
  .InfoBox-header img {
    margin-top: 20px;
  }
  .InfoBox-header p {
    margin-right: 13px;
  }
}

.InfoBox-sum {
  background-color: rgba(0, 0, 0, .07);
}

.PlayerEditor hr {
  border: 0;
  height: 0;
}

.PlayerEditor input[type="text"] {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 10px;
  text-align: center;
  font-size: 150%;
}

.PlayerEditor .caution {
  font-size: 90%;
  margin-top: 20px;
}

.PlayerEditor .caution h2 {
  font-weight: 500;
}

.MenuOption {
  position: absolute;
  right: 10px;
  background-color: rgba(0, 0, 0, .3);
  color: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-size: 12px;
  z-index: 0;
}

.MenuOption:hover {
  cursor: pointer;
}

.pointer-device .MenuOption:hover {
  opacity: .7;
}

.MenuOption.add {
  top: 34px;
  font-size: 16px;
}

.MenuOption.reset {
  top: 90px;
}

.MenuOption.reset-gameOver {
  top: 90px;
  background-color: #35a987;
}

.MenuOption.gameType {
  writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
          text-orientation: mixed;
  transform: rotate(180deg);
  top: 125px;
  height: 70px!important;
  border-radius: 13px;
}

.MenuOption.info {
  bottom: 10px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .MenuOption {
    right: 20px;
    width: 24px;
    height: 24px;
  }

  .MenuOption.add {
    top: 43px;
  }

  .MenuOption.reset,
  .MenuOption.reset-gameOver {
    top: 110px;
  }

  .MenuOption.gameType {
    top: 150px;
  }

  .MenuOption.info {
    bottom: 20px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .MenuOption {
    width: 27px;
    height: 27px;
  }

  .MenuOption.add {
    top: 50px;
  }

  .MenuOption.reset-gameOver,
  .MenuOption.reset {
    top: 120px;
  }

  .MenuOption.gameType {
    top: 160px;
  }
}

.MenuOption-disabled {
  pointer-events: none;
  cursor: default;
  opacity: .2;
}

.pointer-device .MenuOption-disabled:hover {
  opacity: .2;
}

.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 10px;
  min-height: 470px;
}

.AppArea {
  height: 100%;
  float: left;
  flex-grow: 1;
}

.AppArea-header {
  height: 69px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.AppArea .caution {
  font-size: 90%;
  margin-top: 20px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .App {
    padding: 20px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .AppArea-header {
    height: 85px;
  }
}

