.NumPad hr {
  border: 0;
  height: 0;
}

.NumPad .Button {
  text-align: center;
  background-color: #3c82d1;
  color: #fff;
  border: 0;
  border-radius: 5px;
  margin: 4px;
  width: 40px;
  height: 40px;
  font-size: 120%;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-appearance: none;
  padding: 0;
}

.NumPad .Button:focus {
  outline: none;
}

.NumPad .Button:hover {
  opacity: .8!important;
}

.NumPad .Button-selected {
  border: 2px solid rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.3);
}

.ThrowCounter {
  margin: 20px 0 15px 0;
}

.NumPad .ThrowCounter .throws {
  display: inline-block;
  margin: 0;
  font-size: 120%;
  width: 115px;
  pointer-events: none;
  user-select: none;
}

.NumPad .ThrowCounter .throws b {
  font-weight: 500;
}

.NumPad .ThrowCounter .ActionButton {
  height: 35px;
  width: 35px;
  padding: 0;
  margin: 0;
}


@media screen and (min-width: 400px) and (min-height: 510px) {
  .NumPad .Button {
    width: 45px;
    height: 45px;
    margin: 7px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .NumPad .Button {
    width: 55px;
    height: 55px;
  }
}
