.MenuOption {
  position: absolute;
  right: 10px;
  background-color: rgba(0, 0, 0, .3);
  color: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-size: 12px;
  z-index: 0;
}

.MenuOption:hover {
  cursor: pointer;
}

.pointer-device .MenuOption:hover {
  opacity: .7;
}

.MenuOption.add {
  top: 34px;
  font-size: 16px;
}

.MenuOption.reset {
  top: 90px;
}

.MenuOption.reset-gameOver {
  top: 90px;
  background-color: #35a987;
}

.MenuOption.gameType {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  top: 125px;
  height: 70px!important;
  border-radius: 13px;
}

.MenuOption.info {
  bottom: 10px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .MenuOption {
    right: 20px;
    width: 24px;
    height: 24px;
  }

  .MenuOption.add {
    top: 43px;
  }

  .MenuOption.reset,
  .MenuOption.reset-gameOver {
    top: 110px;
  }

  .MenuOption.gameType {
    top: 150px;
  }

  .MenuOption.info {
    bottom: 20px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .MenuOption {
    width: 27px;
    height: 27px;
  }

  .MenuOption.add {
    top: 50px;
  }

  .MenuOption.reset-gameOver,
  .MenuOption.reset {
    top: 120px;
  }

  .MenuOption.gameType {
    top: 160px;
  }
}

.MenuOption-disabled {
  pointer-events: none;
  cursor: default;
  opacity: .2;
}

.pointer-device .MenuOption-disabled:hover {
  opacity: .2;
}
