.Scoresheet {
  height: 100%;
  float: left;
}

.Scoresheet-column {
  height: 100%;
  float: left;
  border-right: 1px solid rgba(0, 0, 0, .5);
}

.Scoresheet-main {
  height: calc(100% - 70px);
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .Scoresheet-main {
    height: calc(100% - 86px);
  }
}

.InfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 0 10px;
}

.InfoBox-header {
  height: 70px;
  display: block;;
}

.InfoBox-header p {
  font-size: 50%;
  text-align: right;
  margin-top: -5px;
}

.InfoBox-header img {
  width: 70px;
  margin-top: 20px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .InfoBox-header img {
    width: 90px;
    margin-top: 13px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .InfoBox-header {
    height: 86px;
  }
  .InfoBox-header img {
    margin-top: 20px;
  }
  .InfoBox-header p {
    margin-right: 13px;
  }
}

.InfoBox-sum {
  background-color: rgba(0, 0, 0, .07);
}
