.Modal {
  font-size: 10px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .Modal {
    font-size: 13px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .Modal {
    font-size: 16px;
  }
}

.Modal-window {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 75%;
  max-width: 900px;
  height: 85%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  z-index: 10;
  box-shadow: 0 8px 6px -6px #000;
}

.Modal-window h1 {
  display: block;
  text-align: center;
  font-size: 150%;
  margin-top: 7px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Modal-window h2 {
  display: block;
  text-align: center;
  font-size: 120%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Modal-area {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.Modal-area p {
  margin-bottom: 10px;
  line-height: 150%;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .Modal-area p {
    margin-bottom: 15px;
  }
}

.Modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .7);
}

.Modal-exit {
  position: absolute;
  right: 0;
  margin-right: 20px;
  font-weight: 500;
  border: 2px solid rgba(0, 0, 0, .3);
  color: rgba(0, 0, 0, .3);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: #fff;
}

.Modal-exit::after {
  content: 'x';
}

.Modal-exit:hover {
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, .9);
  color: rgba(0, 0, 0, .9);
}
