.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 10px;
  min-height: 470px;
}

.AppArea {
  height: 100%;
  float: left;
  flex-grow: 1;
}

.AppArea-header {
  height: 69px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.AppArea .caution {
  font-size: 90%;
  margin-top: 20px;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .App {
    padding: 20px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .AppArea-header {
    height: 85px;
  }
}
