.SumBox {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .07);
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .SumBox {
    font-size: 85%;
  }
}

.SumBox-balance {
  font-size: 90%;
}

.SumBox .SumBox-balance--positive {
  color: #3c82d1;
}

.SumBox .SumBox-balance--negative {
  color: #dc3a1f;
}

.SumBox-warning {
  color: #dc3a1f;
}
