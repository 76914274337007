.ScoreBox {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .ScoreBox {
    font-size: 85%;
  }
}

.pointer-device .ScoreBox:hover {
  background-color: rgba(0, 0, 0, .05);
}

.ScoreBoxValue {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ScoreBoxValue-wiped::after {
  content: 'x';
  color: #dc3a1f;
}

.Player-done .ScoreBoxValue {
  position: relative;
  opacity: .3;
  z-index: 0;
}
