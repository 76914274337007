.PlayerEditor hr {
  border: 0;
  height: 0;
}

.PlayerEditor input[type="text"] {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 10px;
  text-align: center;
  font-size: 150%;
}

.PlayerEditor .caution {
  font-size: 90%;
  margin-top: 20px;
}

.PlayerEditor .caution h2 {
  font-weight: 500;
}
