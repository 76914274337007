.Player {
  width: calc(90vh / 20);
  min-width: 25px;
  height: 100%;
  float: left;
  border-right: 1px solid rgba(0, 0, 0, .4);
}

.Player-name {
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.Player-throws {
  background-color: #35a987;
  color: #fff;
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
  pointer-events: none;
}

.Player-throws.positive-false {
  background-color: #dc3a1f;
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .Player-name {
    height: 85px;
  }

  .Player-throws {
    height: 20px;
    width: 20px;
    margin-left: -4px;
    margin-top: -4px;
    font-size: 10px;
    line-height: 20px;
  }
}

.Player-name:hover {
  cursor: pointer;
}

.pointer-device .Player-name:hover {
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  padding-top: 1px;
}

.Player-name p {
  position: relative;
  z-index: 0;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}

.Player-leader .Player-name p {
  font-weight: 500;
}

.Player-active {
  background-color: rgba(53, 169, 135, 0.1);
}

.Player-inactive .ScoreBox-disabled {
  pointer-events: none;
}

.Player-winner .GrandTotal {
  color: #3c82d1;
  font-weight: 500;
}
