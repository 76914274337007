.ActionButton {
  text-align: center;
  background-color: #3c82d1;
  color: #fff;
  border: 0;
  border-radius: 5px;
  margin: 4px;
  height: 40px;
  padding: 0 20px;
  font-size: 90%;
  max-width: 100%;
  cursor: pointer;
  text-transform: uppercase;
}

.ActionButton:focus {
  outline: none;
}

.ActionButton:hover {
  opacity: .8;
}

@media screen and (min-width: 400px) and (min-height: 510px) {
  .ActionButton {
    height: 45px;
    margin: 7px;
  }
}

@media screen and (min-width: 400px) and (min-height: 710px) {
  .ActionButton {
    height: 55px;
  }
}

.ActionButton-red {
  background-color: #dc3a1f;
}

.ActionButton-green {
  background-color: #35a987;
}

.ActionButton-blue {
  background-color: #3c82d1;
}

.ActionButton-disabled {
  pointer-events: none;
  opacity: .3;
}

.ActionButton-disabled:hover {
  opacity: .3;
  cursor: default;
}
